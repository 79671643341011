import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, isEmpty } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/api/services/user.service';
import { User } from 'src/app/interfaces/User';
import { CompanyService } from 'src/app/api/services/company.service';
import { CompaniesTagComponent } from '../companies-tag/companies-tag.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('drawer', { static: false }) drawer: any;
  public selectedItem: string = '';
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result: BreakpointState) => result.matches));

  public user: User = {} as User;
  private isUserLogged = "";

  constructor(
    private fs: FirebaseService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public us: UserService,
    private cs: CompanyService
  ) {
    this.matIconRegistry.addSvgIcon("qrcode", this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/qr-code.svg"));
  }

  ngOnInit() {
    // if (Object.keys(this.us.User).length === 0) {
    //     console.log("User vuoto");
    //     console.log(this.us.User);
    // }
    this.isUserLogged = localStorage.getItem("tokenid");
    // console.log("UserLogged: ", isUserLogged);
    if (!this.isUserLogged) {
      this.logout();
    }
  }

  closeSideNav() {
    if (this.drawer._mode == 'over') {
      this.drawer.close();
    }
  }

  public logout() {
    this.fs.Logout().then(() => {
      localStorage.clear();
      this.router.navigateByUrl("login");
    });
  }

  public navigateTo(viewName: string) {
    let comp: CompaniesTagComponent = {} as CompaniesTagComponent;
    if (viewName == "companies/tags") {
      let isUserLogged = localStorage.getItem("tokenid");
      let useremail = localStorage.getItem("useremail");
      if (isUserLogged) {
        this.fs.GetUserData(useremail).then((res) => {

          this.us.User = res;
          this.fs.GetCompany(this.us.User.company).then((cpres)=>{
            this.cs.Company = cpres;
            this.router.navigateByUrl(viewName);
            
            })
        });
      }
    }
    else 
      this.router.navigateByUrl(viewName);
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebase: {
  //   apiKey: "AIzaSyDY3WMf3X9cmcc1FKyoLYg8oYx1Vl2bcZQ",
  //   authDomain: "tagcloud-dqa.firebaseapp.com",
  //   databaseURL: "https://tagcloud-dqa.firebaseio.com",
  //   projectId: "tagcloud-dqa",
  //   storageBucket: "tagcloud-dqa.appspot.com",
  //   messagingSenderId: "195928345648",
  //   appId: "1:195928345648:web:e8ca77237c13419b"
  // },
  firebase: {
    apiKey: "AIzaSyDY3WMf3X9cmcc1FKyoLYg8oYx1Vl2bcZQ",
    authDomain: "tagcloud-dqa.firebaseapp.com",
    databaseURL: "https://tagcloud-dqa.firebaseio.com",
    projectId: "tagcloud-dqa",
    storageBucket: "tagcloud-dqa.appspot.com",
    messagingSenderId: "195928345648",
    appId: "1:195928345648:web:e8ca77237c13419b",
    measurementId: "G-QXN156P9EY"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

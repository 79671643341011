import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { UserService } from 'src/app/api/services/user.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public usersList_columns: Array<string> = ['ID','Email','Company']
  public dataSource;

  constructor(
    private fs: FirebaseService,
    private us: UserService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.us.User.level != 0 && this.us.User.level != 1) //View enabled only for admin
      this.router.navigateByUrl("");
    
    if (this.us.User.level == 0) {
      this.fs.GetUsersList().then((users) => {
        this.dataSource = new MatTableDataSource(users);
      });
    }
    else {
      this.fs.GetCompanyUsersList(this.us.User.company).then((users) => {
        this.dataSource = new MatTableDataSource(users);
      });
    }
    
  }

  public usersTable_filter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public addUser() {
    const dialog = this.dialog.open(AddUserDialogComponent);
    dialog.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { TagService } from 'src/app/api/services/tag.service';
import { Tag } from 'src/app/interfaces/Tag';
import { VCard } from 'src/app/interfaces/VCard';
import { MatSnackBar, MatDialog } from '@angular/material';
import { QrcodeDialogComponent } from 'src/app/dialogs/qrcode-dialog/qrcode-dialog.component';

@Component({
  selector: 'app-edit-code',
  templateUrl: './edit-code.component.html',
  styleUrls: ['./edit-code.component.scss']
})
export class EditCodeComponent implements OnInit {

  public tag: Tag = {} as Tag;
  public vcard: VCard = {} as VCard;

  constructor(
    private fs: FirebaseService,
    private tagServ: TagService,
    private snackbar: MatSnackBar,
    private dialog:MatDialog
  ) { }

  ngOnInit() {
    if (this.tagServ.Tag.transparent)
      this.tagServ.Tag.light =  this.tagServ.Tag.light.substring(0, this.tagServ.Tag.light.length - 2);

    this.tag = this.tagServ.Tag;

    if (this.tag.dataType == 1)
      this.tag.data = JSON.parse(this.tag.data);
    else if (this.tag.dataType == 0)
      this.vcard = JSON.parse(this.tag.data);
  }

  editCode() {
    if (this.tag.dataType == 0)
      this.setContactTagData();

    if (!this.tag.data)
      this.openSnackBar("Compila correttamente i campi", "Ok");
    else {
      if (!this.tag.description)
        this.openSnackBar("Inserisci la descrizione del tag", "Ok");
      else {
        if (this.tag.transparent == true)
          this.tag.light += "00";

        if (this.tag.dataType == 1)
          this.tag.data = JSON.stringify(this.tag.data);

          this.fs.EditTag(this.tag).then(()=> {
            this.openDialog(this.tag);
            if (this.tag.dataType == 1) {
              this.tag.data = this.tag.data.replace(/"/g, "");
            }
          });
      }
    }
  }

  private setContactTagData() {
    //set workemail
    if (!this.vcard.workEmailGroup) {
      this.vcard.workEmailGroup = [];
      this.vcard.workEmailGroup.push(this.vcard.workEmail);
      this.vcard.workEmail = '';
    } else {
      if (this.vcard.workEmail){
        this.vcard.workEmailGroup.push(this.vcard.workEmail);
        this.vcard.workEmail = '';
      }
    }

    //set workphone
    if (!this.vcard.cellPhoneGroup) {
      this.vcard.cellPhoneGroup = [];
      this.vcard.cellPhoneGroup.push(this.vcard.cellPhone);
      this.vcard.cellPhone = '';
    } else {
      if (this.vcard.cellPhone) {
        this.vcard.cellPhoneGroup.push(this.vcard.cellPhone);
        this.vcard.cellPhone = '';
      }
    }
    this.tag.data = JSON.stringify(this.vcard);
  }

  private openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 5000,
    });
  }

  public openDialog(selectedTag: Tag): void {
    this.tagServ.Tag = selectedTag;
    const dialogRef = this.dialog.open(QrcodeDialogComponent, {
      width: '350px',
    });
  }

  public addEmailField() {
    if (this.vcard.workEmail) {
      this.vcard.workEmailGroup.push(this.vcard.workEmail);
      this.vcard.workEmail = '';
    }
  }

  public removeEmailField(item) {
    this.vcard.workEmailGroup.splice(this.vcard.workEmailGroup.indexOf(item),1);
  }

  public addPhoneField() {
    if (this.vcard.cellPhone) {
      this.vcard.cellPhoneGroup.push(this.vcard.cellPhone);
      this.vcard.cellPhone = '';
    }
  }

  public removePhoneField(item) {
    this.vcard.cellPhoneGroup.splice(this.vcard.cellPhoneGroup.indexOf(item),1);
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

}
